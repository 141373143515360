import { DormerRootState } from "../DormerRootState";
import {SurveyAnswerViewModel, SurveyQuestionViewModel} from "@hec/models";

export const getSurveyQuestionsSelector = (state: DormerRootState): SurveyQuestionViewModel[] => {
  return state.surveyQuestions.surveyQuestionViewModels;
}

export const getSurveyAnswersSelector = (state: DormerRootState):  SurveyAnswerViewModel[] => {
  return state.surveyQuestions.submittedAnswers;
}


export const getSurveyQuestionsAreLoadedSelector = (state: DormerRootState): boolean => {
  return state?.surveyQuestions?.loaded ?? false;
}

export const getSurveyQuestionHoldingAnswersSelector = (state: DormerRootState): SurveyAnswerViewModel[] => {
  return state.surveyQuestions.holdingAnswers;
}
