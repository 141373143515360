import produce from 'immer';
import {
  getSurveyAnswersSucceededAction,
  getSurveyQuestionsSucceededAction,
  holdSurveyAnswersAction,
  putSurveyAnswersSucceededAction
} from './Actions';
import {SurveyQuestionsState} from "./State";



export const getSurveyQuestionsSucceededReducer = (state: SurveyQuestionsState, action: ReturnType<typeof getSurveyQuestionsSucceededAction>) => {
  return produce(state, (draft) => {
    const surveyQuestionViewModels = action.payload.surveyQuestionViewModels;

    draft.surveyQuestionViewModels = surveyQuestionViewModels;
    draft.loaded = true;
  });
}



export const holdSurveyAnswerReducer = (state: SurveyQuestionsState, action: ReturnType<typeof holdSurveyAnswersAction>) => {
  return produce(state, (draft) => {
    const filterIds = action.payload.surveyAnswerViewModels.map(x => x.questionId);

    const holdingAnswers = draft.holdingAnswers.filter(x=> filterIds.indexOf(x.questionId) === -1);

    holdingAnswers.push(...action.payload.surveyAnswerViewModels);

    draft.holdingAnswers = holdingAnswers;
    draft.loaded = true;
  });
}


export const putSurveyAnswersSucceededReducer = (state: SurveyQuestionsState, action: ReturnType<typeof putSurveyAnswersSucceededAction>) => {
  return produce(state, (draft) => {
    draft.submittedAnswers = action.payload.surveyAnswerViewModels;
  });
}

export const getSurveyAnswersSucceededReducer = (state: SurveyQuestionsState, action: ReturnType<typeof getSurveyAnswersSucceededAction>) => {
  return produce(state, (draft) => {
    draft.submittedAnswers = action.payload.surveyAnswerViewModels;
  });
}
