import React, {FunctionComponent, useEffect, useMemo, useState} from "react";
import styles from "../frames/frames.module.scss";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {DisplaySurveyQuestions, SidebarFooter, Text} from "@hec/components/v2";
import {RoutePaths} from "../../routing";
import {NonFileSurveyQuestionTypes, SurveyAnswerViewModel, SurveyQuestionType, TranslationKeys} from "@hec/models";
import {
  getClientInformationSelector, getFaceConfiguration, getOrderSelector,
  getSelectedConfigurationTemplateSelector, getSurveyAnswersSelector, getSurveyQuestionHoldingAnswersSelector,
  getSurveyQuestionsAction,
  getSurveyQuestionsAreLoadedSelector,
  getSurveyQuestionsSelector, holdSurveyAnswersAction,
  putSurveyAnswersAction
} from "@hec/dal";
import {CANVAS_TECHNICAL_FRONT_ID, CANVAS_THREE_ID, ExtraInformationForKeyRenderer} from "../../components";
import {Side} from "@hec/api-dtos";
import {getSplinterWidth, getWidthOfStaticSections} from "@hec/core";

export const TechnicalSideBarContent: FunctionComponent = () => {
  const [answersState, setAnswersStateState] = useState<SurveyAnswerViewModel[]>();

  const dispatchUpdates = (answers?: SurveyAnswerViewModel[]) => {
    const answersValue = answers ?? answersState;

    const preAnswered = surveyQuestionHoldingAnswers.map(x => x.questionId);

    const putMeDaddy = (answersValue ?? []).map(e => new SurveyAnswerViewModel(
      e.questionId,
      e.surveyQuestionType,
      e.getNormalizedValueFromForm(),
    )).filter(x => preAnswered.indexOf(x.questionId) === -1);

    putMeDaddy.push(...surveyQuestionHoldingAnswers);
    dispatch(putSurveyAnswersAction({orderId: order!.id, surveyAnswerViewModels: putMeDaddy}));
  }


  const setAnswers = (answers: SurveyAnswerViewModel[]) => {
    const nonFileAnswers = answers.filter(x => NonFileSurveyQuestionTypes.indexOf(x.surveyQuestionType) !== -1);

    dispatchUpdates(nonFileAnswers);
    setAnswersStateState(answers);
  }


  const {t} = useTranslation();
  const dispatch = useDispatch();

  const clientInformation = useSelector(getClientInformationSelector);
  const surveyQuestions = useSelector(getSurveyQuestionsSelector);
  const surveyAnswers = useSelector(getSurveyAnswersSelector);
  const surveyQuestionHoldingAnswers = useSelector(getSurveyQuestionHoldingAnswersSelector);
  const order = useSelector(getOrderSelector);
  const faceConfiguration = useSelector(getFaceConfiguration);
  const loaded = useSelector(getSurveyQuestionsAreLoadedSelector);


  const selectedConfigurationTemplate = useSelector(getSelectedConfigurationTemplateSelector);


  const totalFrameWidth = useMemo(() => {
    const displayWidth = faceConfiguration?.width ?? 0;
    const southFace = faceConfiguration?.faces?.find(x => x.side === Side.South);

    const widthOfStaticSections = getWidthOfStaticSections(southFace);

    return displayWidth - widthOfStaticSections + getSplinterWidth(southFace);

  }, [faceConfiguration, order, faceConfiguration?.width]);


  useEffect(() => {
    if (!loaded && selectedConfigurationTemplate?.id != null) {
      dispatch(getSurveyQuestionsAction({
        configurationTemplateId: selectedConfigurationTemplate?.id
      }));
    }
  }, [selectedConfigurationTemplate, loaded]);


  useEffect(() => {
    setTimeout(() => {
      const desired2DFiles = surveyQuestions.filter(x => x.forPro && x.surveyQuestionType === SurveyQuestionType.ImageTwoDimensions);
      if (desired2DFiles.length > 0 && order?.id != null) {
        try {
          const canvas: any = (document!.getElementById(CANVAS_TECHNICAL_FRONT_ID) as unknown as any).children[0].children[0];

          const base64Image = canvas.toDataURL("image/png");

          console.log(base64Image);

          const desired2DFilesAlphabetically = desired2DFiles.sort((a, b) => a.label.localeCompare(b.label));

          const toUploadFor = desired2DFilesAlphabetically[0];

          const imageFileList = new DataTransfer();
          imageFileList.items.add(new File([Uint8Array.from(atob(base64Image.split(",")[1]), c => c.charCodeAt(0))], "screenshot2d.png", {type: "image/png"}));
          const fileList: FileList = imageFileList.files;


          const holdingAnswer = [new SurveyAnswerViewModel(
            toUploadFor.id,
            toUploadFor.surveyQuestionType,
            fileList,
          )];

          dispatch(holdSurveyAnswersAction({orderId: order.id, surveyAnswerViewModels: holdingAnswer}));

        } catch (e) {
          console.error('error rendering desired 2d base64 screenshot', e);
        }
      }
    }, 250)
  }, [dispatch, surveyQuestions, order]);


  if (!loaded || clientInformation === null) {
    return <>...</>;
  }

  return (
    <>
      <div className={styles.sections}>
        <ExtraInformationForKeyRenderer
          extraInformationKey={'TechinicalStepExtraInformation'}
        />
        <DisplaySurveyQuestions
          surveyQuestionViewModels={surveyQuestions.filter(e => e.forPro === true)}
          surveyAnswersViewModels={surveyAnswers}
          surveyAnswerViewModelsChanged={setAnswers}
        />
        <Text>
          {t(TranslationKeys.dormer.sizeInformation.totalFrameWidth)}: {totalFrameWidth} mm
        </Text>
      </div>
      <SidebarFooter
        prevRoutePath={RoutePaths.CustomerSurveyPath}
        nextRoutePath={RoutePaths.SummaryPath}
        nextRouteCTA={t(TranslationKeys.dormer.pages.summaryPage.cta)}
        clientInformation={clientInformation}
        onGoNext={() => {
          dispatchUpdates();
        }}
      />
    </>
  );
};
