import React, {FunctionComponent, useEffect, useState} from "react";
import {Input, TextArea} from "../../v1";
import {SurveyAnswerViewModel, SurveyQuestionType, SurveyQuestionViewModel} from "@hec/models";
import styles from './Survey.module.scss';
import {FileInput} from "../../../../models/src/SurveyQuestions/FileInput";

interface DisplaySurveyQuestionsProps {
  surveyQuestionViewModels: SurveyQuestionViewModel[];
  surveyAnswersViewModels: SurveyAnswerViewModel[];
  surveyAnswerViewModelsChanged: (vms: SurveyAnswerViewModel[]) => void;
}

export const DisplaySurveyQuestions: FunctionComponent<DisplaySurveyQuestionsProps> = ({
                                                                                         surveyQuestionViewModels,
                                                                                         surveyAnswersViewModels,
                                                                                         surveyAnswerViewModelsChanged
                                                                                       }: DisplaySurveyQuestionsProps) => {

  // For easier debugging, break duplicate from inspector
  const debugSurveyAnswersViewModels: SurveyAnswerViewModel[] = [...surveyAnswersViewModels,
    // new SurveyAnswerViewModel("c2fc17d7-7475-4115-9cfb-36077f001f51", SurveyQuestionType.File, {value: "https://s3.eu-west-2.amazonaws.com/cdn.aanbouwconfigurator.com/order-assets/org-7385a4a2-640d-4769-9252-6a5d64461ef8/order-f8cc49ef-4611-42aa-975a-b8377b07ddb2/508d742d-650f-4aa5-af92-3b231b6de050.png"})
  ];

  return <>
    <SurveyComponent
      questions={surveyQuestionViewModels}
      surveyAnswersViewModels={debugSurveyAnswersViewModels}
      surveyAnswerViewModelsChanged={surveyAnswerViewModelsChanged}
    />
  </>;
};

interface Answers {
  [key: string]: any;
}

const SurveyComponent = ({questions, surveyAnswersViewModels, surveyAnswerViewModelsChanged}: {
  questions: SurveyQuestionViewModel[],
  surveyAnswersViewModels: SurveyAnswerViewModel[],
  surveyAnswerViewModelsChanged: (vms: SurveyAnswerViewModel[]) => void
}) => {
  const getDefaultValues = (questions: SurveyQuestionViewModel[]) => {
    return questions.reduce<Answers>((acc, question) => {

      let currentValue = question.defaultValue ? (question.defaultValue as any).value : '';

      const currentAnswer = surveyAnswersViewModels.find(x => x.questionId === question.id);
      if (currentAnswer?.value) {
        try {
          currentValue = (currentAnswer.value as any)?.value;
        } catch (e) {
          // Prevent fata, errors here should not prevent default data from showing
          console.error('Was unable to restore form date, details included',
            {
              question,
              currentAnswer,
              exception: e
            });
        }
      }
      // TODO: make work

      switch (question.surveyQuestionType) {
        case SurveyQuestionType.Date:
          // Date type to YYYY-MM-DD
          currentValue = currentValue ? new Date(currentValue).toISOString().split('T')[0] : '';
          break;
        case SurveyQuestionType.Toggle:
          currentValue = typeof currentValue === 'boolean' ? currentValue : false;
          break;
        case SurveyQuestionType.Text:
        case SurveyQuestionType.MultilineText:
          currentValue = typeof currentValue === 'string' ? currentValue : '';
          break;
      }

      if (question.id && currentValue !== null && currentValue !== undefined) {
        acc[question.id] = currentValue;
      }

      return acc;
    }, {});
  };


  const [answers, setAnswers] = useState<Answers>(getDefaultValues(questions));

  useEffect(() => {
    const keys = Object.keys(answers);
    const vms = keys.map((a: any) => {
      const vm = answers[a];
      const question = questions.find(e => e.id === a);


      return new SurveyAnswerViewModel(question!.id, question!.surveyQuestionType, vm);
    });

    surveyAnswerViewModelsChanged(vms);
  }, [answers]);

  const handleAnswerChange = (questionId: string, answer: any) => {
    setAnswers(prev => ({...prev, [questionId]: answer}));
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === '.') {
      e.preventDefault();
    }
  }

  const renderInputQuestion = (question: SurveyQuestionViewModel, type: string) => {
    const isDecimal = type === 'decimal';
    const isNumber = type !== 'text' && type !== 'date';

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      handleAnswerChange(question.id, value);
    };

    return (
      <div className={styles.inputGroup}>
        <label htmlFor={question.id}>{question.label}</label>
        <Input
          id={question.id}
          type={isNumber ? "number" : type}
          className={styles.formControl}
          value={answers[question.id] ?? ''}
          onChange={handleChange}
          onKeyDown={isNumber && !isDecimal ? handleKeyDown : undefined}
        />
      </div>
    );
  };

  // const renderInputQuestion = (question: SurveyQuestionViewModel, type: string) => {
  //
  //   const isDecimal = type === 'decimal';
  //   const inputTypeForHtmlElement = isDecimal ? 'number' : type;
  //
  //   return (
  //     <div className={styles.inputGroup}>
  //       <label htmlFor={question.id}>{question.label}</label>
  //       <Input
  //         id={question.id}
  //         type={type}
  //         className={styles.formControl}
  //         value={answers[question.id] ?? ''}
  //         onChange={(e) => handleAnswerChange(question.id, (e.target as any).value)}
  //       />
  //     </div>
  //   );
  // };

  const renderCheckBoxInputQuestion = (question: SurveyQuestionViewModel) => {
    return (
      <div className={styles.checkboxInputGroup}>
        <input
          id={question.id}
          type="checkbox"
          className={styles.checkbox}
          checked={!!answers[question.id]}
          onChange={(e) => handleAnswerChange(question.id, e.target.checked)}
        />
        <label htmlFor={question.id} className={styles.checkboxLabel}>{question.label}</label>
      </div>
    );
  }


  const renderSelectQuestion = (question: SurveyQuestionViewModel) => {
    const options = question.metaData as { value: string[] };
    return (
      <div className={styles.inputGroup}>
        <label htmlFor={question.id}>{question.label}</label>
        <select
          id={question.id}
          className={styles.select}
          value={answers[question.id] ?? ''}
          onChange={(e) => handleAnswerChange(question.id, e.target.value)}
        >
          {options.value.map((option, index) => (
            <option value={option} key={index}>
              {option}
            </option>
          ))}
        </select>
      </div>
    );
  };

  const renderTextAreaQuestion = (question: SurveyQuestionViewModel) => {
    return (
      <div className={styles.inputGroup}>
        <label htmlFor={question.id}>{question.label}</label>
        <TextArea
          id={question.id}
          className={styles.formControl}
          value={answers[question.id] ?? ''}
          onChange={(e) => handleAnswerChange(question.id, (e.target as any).value)}
        />
      </div>
    );
  };


  const renderFileInputQuestion = (question: SurveyQuestionViewModel) => {
    const answer = answers[question.id];

    return (
      <div className={styles.inputGroup}>
        <label htmlFor={question.id}>{question.label}</label>
        <FileInput
          hasExistingFile={!!answer}
          onChange={(e) => handleAnswerChange(question.id, e.target.files)}
        />
        {/*<input*/}
        {/*  id={question.id}*/}
        {/*  type="file"*/}
        {/*  className={styles.fileInput}*/}
        {/*  onChange={(e) => handleAnswerChange(question.id, e.target.files)}*/}
        {/*/>*/}
      </div>
    );
  }


  const renderQuestion = (question: SurveyQuestionViewModel) => {
    switch (question.surveyQuestionType) {
      case SurveyQuestionType.WholeNumber:
        return renderInputQuestion(question, 'number');
      case SurveyQuestionType.Decimal:
        return renderInputQuestion(question, 'decimal');
      case SurveyQuestionType.Toggle:
        return renderCheckBoxInputQuestion(question);
      case SurveyQuestionType.Date:
        return renderInputQuestion(question, 'date');
      case SurveyQuestionType.Text:
        return renderInputQuestion(question, 'text');
      case SurveyQuestionType.MultilineText:
        return renderTextAreaQuestion(question);
      case SurveyQuestionType.DropDown:
        return renderSelectQuestion(question);
      case SurveyQuestionType.File:
        return renderFileInputQuestion(question);
      default:
        return null;
    }
  };

  return (
    <div className={styles.surveyContainer}>
      {questions.map(question => (
        <div key={question.id} className={styles.surveyQuestion}>
          {renderQuestion(question)}
        </div>
      ))}
    </div>
  );
};
