import React, { useState } from 'react'
import { useDropzone, DropzoneOptions } from 'react-dropzone'
import { useCustomTranslation } from '@hec/dal'

interface FileInputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  maxSize?: number
  hasExistingFile?: boolean
}

export const FileInput: React.FC<FileInputProps> = ({
                                                      onChange,
                                                      maxSize,
                                                      hasExistingFile,
                                                      ...props
                                                    }) => {
  const maxFiles = 1
  const { t } = useCustomTranslation()
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([])

  const onDrop: DropzoneOptions['onDrop'] = (acceptedFiles) => {
    const dataTransfer = new DataTransfer()
    acceptedFiles.forEach((file) => dataTransfer.items.add(file))
    setUploadedFiles([...acceptedFiles])
    const event = {
      target: { files: dataTransfer.files },
    } as unknown as React.ChangeEvent<HTMLInputElement>
    onChange(event)
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles,
    maxSize,
  })

  return (
    <div
      {...getRootProps()}
      style={{
        border: '2px dashed #ccc',
        padding: '20px',
        borderRadius: '5px',
        cursor: 'pointer',
        textAlign: 'left',
        fontFamily: 'Arial, sans-serif',
      }}
    >
      <input {...getInputProps()} {...props} />
      {uploadedFiles.length > 0 ? (
        <>
          {uploadedFiles.map((file, index) => (
              <u>
              {file.name}
            </u>
          ))}
        </>
      ) : (
        <u>{hasExistingFile === true ? t('fileinput.replace-upload-link', 'Vervang bestand') : t('fileinput.empty-upload-link', 'Upload')}</u>
      )}
    </div>
  )
}
